import React from 'react';
import { withRouter } from 'react-router-dom';
import { stringify, parse } from '../../../util/urlHelpers';
import { SCHEMA_TYPE_MULTI_ENUM } from '../../../util/types';
import SelectMultipleFilter from './SelectMultipleFilter';
import { FieldTextInput } from '../../../components';
import { brandsObject } from '../../../config/configBrands';
import { colorConfig } from '../../../config/configColours';

import css from './selectMultiplerFilterCustom.module.css';

const URL_PARAM = 'pub_colourArray';

const options = colorConfig.map(row => {
  return {
    option: row.value,
    label: row.label,
    // color: row.color,
  };
});

const handleSubmit = (values, history, location) => {
  console.log('Submitting values', values);
  // Parse the current search parameters
  const currentParams = parse(location.search);

  delete currentParams.page;
  values.page = 1;
  // Merge the current parameters with the new values
  const updatedParams = { ...currentParams, ...values };
  // Stringify the merged parameters
  const queryParams = stringify(updatedParams);
  // Update the URL with the merged parameters
  history.push(`${window.location.pathname}?${queryParams}`);
};

const ColourFilterPopup = withRouter(props => {
  const { history, location } = props;

  const params = parse(location.search);
  const amenities = params[URL_PARAM];
  const initialValues = { [URL_PARAM]: !!amenities ? amenities : null };

  return (
    <SelectMultipleFilter
      id="SelectColourFilter"
      name="colourArray"
      queryParamNames={[URL_PARAM]}
      label="Colour"
      onSubmit={values => handleSubmit(values, history, location)}
      showAsPopup={true}
      liveEdit={true}
      options={options}
      schemaType={SCHEMA_TYPE_MULTI_ENUM}
      initialValues={initialValues}
      // contentPlacementOffset={-225}
      searchMode="has_any"
      className={css.maxScroll}
    />
  );
});

export const ColourFilterPopupComponent = {
  component: ColourFilterPopup,
  props: {},
  group: 'page:SearchPage',
};
