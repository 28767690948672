import React from 'react';
import { withRouter } from 'react-router-dom';
import { stringify, parse } from '../../../util/urlHelpers';
import { SCHEMA_TYPE_MULTI_ENUM } from '../../../util/types';
import SelectMultipleFilter from './SelectMultipleFilter';
import { FieldTextInput } from '../../../components';
import { brandsObject } from '../../../config/configBrands';

const URL_PARAM = 'pub_brand';



const id = 'FilterBrand';
const field = <FieldTextInput type="text" id={`brand.input1`} name="input1" label="Input:" />;

const options = {};


const handleSubmit = (values, history) => {
  console.log('Submitting values', values);
  const queryParams = values ? `?${stringify(values)}` : '';
  history.push(`${window.location.pathname}${queryParams}`);
};

const BrandFilterPopup = withRouter(props => {
  const { history, location } = props;

  const params = parse(location.search);
  const amenities = params[URL_PARAM];
  const initialValues = { [URL_PARAM]: !!amenities ? amenities : null };

  return (
    <SelectMultipleFilter
      id="SelectBrandFilter"
      name="brand"
      queryParamNames={[URL_PARAM]}
      label="Brand"
      onSubmit={values => handleSubmit(values, history)}
      showAsPopup={true}
      liveEdit={false}
      options={options}
      schemaType={SCHEMA_TYPE_MULTI_ENUM}
      initialValues={initialValues}
      contentPlacementOffset={-14}
      children={field}
    />
  );
});

export const BrandFilterPopupComponent = {
  component: BrandFilterPopup,
  props: {},
  group: 'page:SearchPage',
};
