import React from 'react';
import { withRouter } from 'react-router-dom';
import { stringify, parse } from '../../../util/urlHelpers';
import { SCHEMA_TYPE_MULTI_ENUM } from '../../../util/types';
import SelectMultipleFilter from './SelectMultipleFilter';
import { FieldTextInput } from '../../../components';
import {
  categories,
  findCategoryById,
  findSubCategoriesById,
  isSubCategory,
  getParentCategory,
} from '../../../config/configCategories';

const URL_PARAM = 'pub_categoryArray';

const handleSubmit = (values, history, location) => {
  // Parse the current search parameters
  const currentParams = parse(location.search);
  // Merge the current parameters with the new values

  delete currentParams.page;
  values.page = 1;

  console.log('vals', values);

  if (values[URL_PARAM]) {
    const categoryValue = values[URL_PARAM];
    const parsedCategories = categoryValue.split(',');
    // remove has_many: from the first param
    if (parsedCategories[0].includes('has_any:')) {
      parsedCategories[0] = parsedCategories[0].replace('has_any:', '');
    }

    //loop through the parsedCategories and check if there's a subcategory selected, check if the parent is also selected, if so remove the parent from the list, the subcategory can be in any position of the array
    for (let i = 0; i < parsedCategories.length; i++) {
      const subCategory = parsedCategories[i];

      if (isSubCategory(subCategory)) {
        console.log('subCategory', subCategory);
        const parentCategory = getParentCategory(categories, subCategory);
        console.log('parentCategory', parentCategory);
        console.log('parsedCategories', parsedCategories);
        if (parsedCategories.includes(parentCategory.id)) {
          console.log('removing parent');
          const index = parsedCategories.indexOf(parentCategory.id);
          parsedCategories.splice(index, 1);
        }
      }
    }
    //put back the has_any: in the first param
    parsedCategories[0] = 'has_any:' + parsedCategories[0];
    values[URL_PARAM] = parsedCategories.join(',');
  }

  const updatedParams = { ...currentParams, ...values };
  // Stringify the merged parameters
  const queryParams = stringify(updatedParams);
  // Update the URL with the merged parameters
  history.push(`${window.location.pathname}?${queryParams}`);
};

const CategoriesFilterPopup = withRouter(props => {
  const { history, location } = props;

  const params = parse(location.search);

  let options = [];

  if (params[URL_PARAM]) {
    //check if there's multiple params use the first one (e.g pub_categoryArray=1&pub_categoryArray=2) split
    const paramsArray = params[URL_PARAM].split(',');

    //remove has_any: from the first param
    if (paramsArray[0].includes('has_any:')) {
      paramsArray[0] = paramsArray[0].replace('has_any:', '');
    }

    if (isSubCategory(paramsArray[0])) {
      const cats = getParentCategory(categories, paramsArray[0]);
      options = cats.subOptions.map(cat => {
        return {
          option: cat.id,
          label: cat.label,
        };
      });
    }

    const subCategories = findSubCategoriesById(categories, paramsArray[0]);

    if (subCategories) {
      options = subCategories.map(subCategory => {
        return {
          option: subCategory.id,
          label: subCategory.label,
        };
      });
    }

    if (!isSubCategory(paramsArray[0])) {
      options.unshift({
        option: paramsArray[0],
        label: 'All ' + findCategoryById(paramsArray[0]).label,
      });
    }
  } else {
    //show all high level categories
    options = categories.map(category => {
      return {
        option: category.id,
        label: category.label,
      };
    });
  }

  const amenities = params[URL_PARAM];
  const initialValues = { [URL_PARAM]: !!amenities ? amenities : null };

  return (
    <SelectMultipleFilter
      id="SelectMultipleFilterPopupExample"
      name="categoryArray"
      queryParamNames={[URL_PARAM]}
      label="Category"
      onSubmit={values => handleSubmit(values, history, location)}
      showAsPopup={true}
      liveEdit={false}
      options={options}
      schemaType={SCHEMA_TYPE_MULTI_ENUM}
      initialValues={initialValues}
      // contentPlacementOffset={-14}
      searchMode="has_any"
    />
  );
});

export const CategoriesFilterPopupComponent = {
  component: CategoriesFilterPopup,
  props: {},
  group: 'page:SearchPage',
};
