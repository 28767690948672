import React, { useState } from 'react';
import { withRouter } from 'react-router-dom';
import { stringify, parse } from '../../../util/urlHelpers';
import { SCHEMA_TYPE_ENUM, SCHEMA_TYPE_MULTI_ENUM } from '../../../util/types';
import SelectMultipleFilter from './SelectMultipleFilter';
import { FieldTextInput } from '../../../components';

import css from './selectMultiplerFilterCustom.module.css';
import { sizing } from '../../../config/configSizing';

const URL_PARAM = 'pub_size';

const id = 'FilterColour';

const getInitialOptions = parsedParams => {
  let defaultOptions = sizing.default.map(row => {
    return {
      option: row,
      label: row,
    };
  });

  if (parsedParams['pub_categoryArray']) {
    const categories = parsedParams['pub_categoryArray'].split(',');
    // remove the word has_any: from all
    categories.forEach((category, index) => {
      categories[index] = category.replace('has_any:', '');
    });

    let newOptions = [];

    if (categories.length > 0) {
      for (let i = 0; i < categories.length; i++) {
        const category = categories[i].split('>');
        for (let i = category.length; i > 0; i--) {
          const path = category.slice(0, i).join('>');
          if (sizing[path]) {
            newOptions.push(sizing[path].map(row => ({ option: row, label: row })));
            break;
          }
        }
      }

      //   console.log('newOptions', newOptions[0]);
      newOptions.push(defaultOptions);
      //   if (newOptions.length > 0) options = newOptions.flat();
      //   setOptions(newOptions);

      // deduplicate the options
      newOptions = newOptions.filter((option, index, self) => {
        return index === self.findIndex(t => t.option === option.option);
      });

      //   console.log(newOptions.flat().length, 'newOptions');
      return newOptions.flat();
    }
  }
  return defaultOptions;
};

const handleSubmit = (values, history, location, setOptions) => {
  //   console.log('Submitting values', values);
  // Parse the current search parameters
  const currentParams = parse(location.search);
  setOptions(getInitialOptions(currentParams));

  delete currentParams.page;
  values.page = 1;

  // Merge the current parameters with the new values
  const updatedParams = { ...currentParams, ...values };
  // Stringify the merged parameters
  const queryParams = stringify(updatedParams);
  // Update the URL with the merged parameters
  history.push(`${window.location.pathname}?${queryParams}`);
};

const SizeFilterPopup = withRouter(props => {
  const { history, location } = props;

  const params = parse(location.search);
  const amenities = params[URL_PARAM];
  const initialValues = { [URL_PARAM]: !!amenities ? amenities : null };

  let options = getInitialOptions(params);

  //   console.log(options);

  const [aOptions, setOptions] = useState(options);

  const field = (
    <input type="text" placeholder="Search" onChange={() => handleOptionFiltering(event)} />
  );

  const handleOptionFiltering = event => {
    const input = event.target.value.toLowerCase();
    options = getInitialOptions(params)
      .filter(row => {
        return row.label.toLowerCase().includes(input.toLowerCase());
      })
      .map(row => {
        // console.log(row);
        return {
          option: row.option,
          label: row.label,
        };
      });
    setOptions(options);
  };

  return (
    <SelectMultipleFilter
      id="SelectSizeFilter"
      name="size"
      queryParamNames={[URL_PARAM]}
      label="Size"
      onSubmit={values => handleSubmit(values, history, location, setOptions)}
      showAsPopup={true}
      liveEdit={false}
      options={aOptions}
      schemaType={SCHEMA_TYPE_ENUM}
      initialValues={initialValues}
      // contentPlacementOffset={-230}
      children={field}
      searchMode="has_any"
      className={css.maxScroll}
    />
  );
});

export const SizeFilterPopupComponent = {
  component: SizeFilterPopup,
  props: {},
  group: 'page:SearchPage',
};
